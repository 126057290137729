import React from 'react';

const AdsHelper = (props) => {
  React.useEffect(() => {
    let hasFrozen = false;

    const freezeScreen = () => {
      if (!hasFrozen) {
        hasFrozen = true;
        document.body.style.pointerEvents = 'none';
        document.body.style.overflow = 'hidden'; // Desativa o scroll
        setTimeout(() => {
          document.body.style.pointerEvents = 'auto';
          document.body.style.overflow = 'auto'; // Reativa o scroll
        }, props.segundos);
      }
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          freezeScreen();
        }
      });
    });

    const contentElement = document.getElementById(props.name);
    if (contentElement) {
      observer.observe(contentElement);
    }

    return () => {
      if (contentElement) {
        observer.unobserve(contentElement);
      }
    };
  }, []);

  return (
    <div
      id={props.name}
      // style={{ width: '100%', height: '200px', border: '1px solid black' }}
    ></div>
  );
};

export default AdsHelper;
