import React, { useEffect } from 'react';
// import AdsMGIDArtigo from '../AdsMGID/AdsMGIDArtigo';
// import AdsNobeta1 from '../AdsNobeta/AdsNobeta1';
import AdsNobeta2 from '../AdsNobeta/AdsNobeta2';
import AdsenseContent2Amp from '../Adsense/AdsenseContent2Amp';

const CleverAds = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('data-cfasync', 'false');
    script.type = 'text/javascript';
    script.id = 'clever-core';
    script.src = `${process.env.PUBLIC_URL}/ScriptAposta.js`;

    document.body.appendChild(script);

    // Cleanup para remover o script ao desmontar o componente
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div class="clever-core-ads"></div>
      <hr />
      {/* <AdsMGIDArtigo /> */}

      <AdsenseContent2Amp />
    </>
  );
};

export default CleverAds;
