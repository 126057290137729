exports.setUtmSession = (valor) => {
  if (window.sessionStorage.getItem('gd_utm') === null) {
    if (window.location.search) {
      var queryString = window.location.search;
      var urlParams = new URLSearchParams(queryString);
      var utm_source = urlParams.get('utm_source');
      var utm_medium = urlParams.get('utm_medium');
      var utm_campaign = urlParams.get('utm_campaign');
      console.log(queryString);
      window.sessionStorage.setItem('gd_utm', queryString);
    }
  }
};

exports.getUtmSession = (url) => {
  var utm = window.sessionStorage.getItem('gd_utm');

  if (utm) {
    return url + utm;
  } else {
    return url;
  }
};
